/* 
f:flex
r:row
l:column
s:flex-start
e:flex-end
c:center
b:space-between
a:space-around 
*/
.fr-c {
	display: flex;
	justify-content: center;
	-moz-box-pack: center;
	-webkit--moz-box-pack: center;
	box-pack: center;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fr-c-s {
	display: flex;
	justify-content: center;
	-moz-box-pack: center;
	-webkit--moz-box-pack: center;
	box-pack: center;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fr-c-e {
	display: flex;
	justify-content: center;
	-moz-box-pack: center;
	-webkit--moz-box-pack: center;
	box-pack: center;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fr-s-s {
	display: flex;
	justify-content:flex-start;
	-moz-box-pack:start;
	-webkit--moz-box-pack:start;
	box-pack:start;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fr-s-c {
	display: flex;
	justify-content:flex-start;
	-moz-box-pack:start;
	-webkit--moz-box-pack:start;
	box-pack:start;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fr-s-e {
	display: flex;
	justify-content:flex-start;
	-moz-box-pack:start;
	-webkit--moz-box-pack:start;
	box-pack:start;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fr-s-b {
	display: flex;
	justify-content:flex-start;
	-moz-box-pack:start;
	-webkit--moz-box-pack:start;
	box-pack:start;
	align-items:baseline;
	box-align:baseline;
	-moz-box-align:baseline;
	-webkit-box-align:baseline;
}
.fr-e-b {
	display: flex;
	justify-content:flex-end;
	-moz-box-pack:end;
	-webkit--moz-box-pack:end;
	box-pack:end;
	align-items:baseline;
	box-align:baseline;
	-moz-box-align:baseline;
	-webkit-box-align:baseline;
}
.fr-b-b {
	display: flex;
	justify-content: space-between;
	-moz-box-pack: justify;
	-webkit--moz-box-pack: justify;
	box-pack: justify;
	align-items:baseline;
	box-align:baseline;
	-moz-box-align:baseline;
	-webkit-box-align:baseline;
}
.fr-a-b {
	display: flex;
	justify-content: space-around;
	align-items:baseline;
	box-align:baseline;
	-moz-box-align:baseline;
	-webkit-box-align:baseline;
}
.fr-e-s {
	display: flex;
	justify-content:flex-end;
	-moz-box-pack:end;
	-webkit--moz-box-pack:end;
	box-pack:end;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fr-e-c {
	display: flex;
	justify-content:flex-end;
	-moz-box-pack:end;
	-webkit--moz-box-pack:end;
	box-pack:end;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fr-e-e {
	display: flex;
	justify-content:flex-end;
	-moz-box-pack:end;
	-webkit--moz-box-pack:end;
	box-pack:end;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fr-b-s {
	display: flex;
	justify-content: space-between;
	-moz-box-pack: justify;
	-webkit--moz-box-pack: justify;
	box-pack: justify;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fr-b-c {
	display: flex;
	justify-content: space-between;
	-moz-box-pack: justify;
	-webkit--moz-box-pack: justify;
	box-pack: justify;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fr-b-e {
	display: flex;
	justify-content: space-between;
	-moz-box-pack: justify;
	-webkit--moz-box-pack: justify;
	box-pack: justify;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fr-a-s {
	display: flex;
	justify-content: space-around;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fr-a-c {
	display: flex;
	justify-content: space-around;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fr-a-e {
	display: flex;
	justify-content: space-around;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fl-c-s {
	display: flex;
	flex-direction: column;
	justify-content: center;
	-moz-box-pack: center;
	-webkit--moz-box-pack: center;
	box-pack: center;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fl-c-e {
	display: flex;
	flex-direction: column;
	justify-content: center;
	-moz-box-pack: center;
	-webkit--moz-box-pack: center;
	box-pack: center;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fl-s-s {
	display: flex;
	flex-direction: column;
	justify-content:flex-start;
	-moz-box-pack:start;
	-webkit--moz-box-pack:start;
	box-pack:start;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fl-s-c {
	display: flex;
	flex-direction: column;
	justify-content:flex-start;
	-moz-box-pack:start;
	-webkit--moz-box-pack:start;
	box-pack:start;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fl-s-e {
	display: flex;
	flex-direction: column;
	justify-content:flex-start;
	-moz-box-pack:start;
	-webkit--moz-box-pack:start;
	box-pack:start;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fl-e-s {
	display: flex;
	flex-direction: column;
	justify-content:flex-end;
	-moz-box-pack:end;
	-webkit--moz-box-pack:end;
	box-pack:end;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fl-e-c {
	display: flex;
	flex-direction: column;
	justify-content:flex-end;
	-moz-box-pack:end;
	-webkit--moz-box-pack:end;
	box-pack:end;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fl-e-e {
	display: flex;
	flex-direction: column;
	justify-content:flex-end;
	-moz-box-pack:end;
	-webkit--moz-box-pack:end;
	box-pack:end;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fl-b-s {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	-moz-box-pack: justify;
	-webkit--moz-box-pack: justify;
	box-pack: justify;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fl-b-c {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	-moz-box-pack: justify;
	-webkit--moz-box-pack: justify;
	box-pack: justify;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fl-b-e {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	-moz-box-pack: justify;
	-webkit--moz-box-pack: justify;
	box-pack: justify;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fl-a-s {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items:flex-start;
	box-align:start;
	-moz-box-align:start;
	-webkit-box-align:start;
}
.fl-a-c {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.fl-a-e {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items:flex-end;
	box-align:end;
	-moz-box-align:end;
	-webkit-box-align:end;
}
.fl-c {
	display: flex;
	flex-direction: column;
	justify-content: center;
	-moz-box-pack: center;
	-webkit--moz-box-pack: center;
	box-pack: center;
	align-items: center;
	box-align: center;
	-moz-box-align: center;
	-webkit-box-align: center;
}
.p-r-30 {
	padding: 0 30px;
	box-sizing: border-box;
}
.p-r-40 {
	padding: 0 40px;
	box-sizing: border-box;
}
.p-r-20 {
	padding: 0 20px;
	box-sizing: border-box;
}
.p-20 {
	padding: 20px;
	box-sizing: border-box;
}
.p-l-40 {
	padding: 40px 0;
	box-sizing: border-box;
}
.p-l-30 {
	padding: 30px 0;
	box-sizing: border-box;
}
.p-l-20 {
	padding: 20px 0;
	box-sizing: border-box;
}
.p-30 {
	padding: 30px;
	box-sizing: border-box;
}
.p-40 {
	padding: 40px;
	box-sizing: border-box;
}
.position-0 {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99999;
}
.bg-repeat {
	background-position: 0 0;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.z-index-max {
	z-index: 9999999;
}
.b {
	font-weight: bold;
}
.c-bg-ff {
	background-color: #fff;
}
.c-bg-f5 {
	background-color: #F6F4F4;
}
.line1 {
	width: 100%;
	height: 1px;
	background-color: #EBEBEB;
}
.popUp {
	width: 100vw;
	height: 100vh;
	background-color: rgba(50, 50, 50, 0.6);
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 99999;
	overflow: hidden;
}
.pop-animation {
	animation: pop 0.3s ease;
}
@keyframes pop {
from {
		height: 0px;
}
to {
		height: 100vh;
}
}
.warp {
	-webkit-flex-wrap: wrap;
	-webkit-box-lines: multiple;
	flex-wrap: wrap;
}
.w-1 {
	width: 100%;
}
.w-100 {
	width: 100vw;
}
.w-5 {
	width: 50%;
}
.h-1 {
	height: 100%;
}
.h-5 {
	height: 50%;
}
.relative {
	position: relative;
}
.m-t-30 {
	margin-top: 30px;
}
.m-b-30 {
	margin-bottom: 30px;
}
.m-t-15 {
	margin-top: 15px;
}
.m-b-15 {
	margin-bottom: 15px;
}
.m-t-40 {
	margin-top: 40px;
}
.m-b-40 {
	margin-bottom: 40px;
}
.m-t-10 {
	margin-top: 10px;
}
.m-b-10 {
	margin-bottom: 10px;
}
.m-t-20 {
	margin-top: 20px;
}
.m-t-25 {
	margin-top: 20px;
}
.m-b-20 {
	margin-bottom: 20px;
}
.m-l-30 {
	margin-left: 30px;
}
.m-r-30 {
	margin-right: 30px;
}
.m-l-40 {
	margin-left: 40px;
}
.m-r-40 {
	margin-right: 40px;
}
.m-l-10 {
	margin-left: 10px;
}
.m-r-10 {
	margin-right: 10px;
}
.m-l-20 {
	margin-left: 20px;
}
.m-r-20 {
	margin-right: 20px;
}
.m-l-80{
	margin-left: 80px;
}
.text-line-1 {
	display: -webkit-box;
	word-break: break-all;
	/*超出文字用省略号代替*/
	text-overflow: ellipsis;
	/*竖直方向的超出和隐藏*/
	-webkit-box-orient: vertical;
	/*设定行数为2*/
	-webkit-line-clamp: 1;
	/*多出部分隐藏*/
	overflow: hidden;
}
.text-line-2 {
	display: -webkit-box;
	word-break: break-all;
	/*超出文字用省略号代替*/
	text-overflow: ellipsis;
	/*竖直方向的超出和隐藏*/
	-webkit-box-orient: vertical;
	/*设定行数为2*/
	-webkit-line-clamp: 2;
	/*多出部分隐藏*/
	overflow: hidden;
}
.flex-1 {
	box-flex:1;
	flex:1;
	-webkit-flex:1;
}
.fl-b-b {
	display: flex;
	align-content: space-between;
	flex-direction: column;
	justify-content: space-between;
	-moz-box-pack: justify;
	-webkit--moz-box-pack: justify;
	box-pack: justify;
}
.hand{
	cursor: pointer;
}
.text-c{
	text-align: center;
}

