
.el-menu{
    background-color: #001529 !important;
}
.danger{
    color: #F56C6C !important;
    font-size: 14px !important;
    padding: 0px !important;
    height: 24px !important;
    line-height: 24px !important;
    margin-right: 10px !important;
}
.success{
    color: #0960BD !important;   /*#409EFF---默认*/
    font-size: 14px !important;
    padding: 0px !important;
    height: 24px !important;
    line-height: 24px !important;
    margin-right: 10px !important;
}
.success i,.danger i{
    font-size: 16px !important;
}
.success.el-button + .el-button{
    margin-left: 0px !important;
}
.el-descriptions {
    padding: 10px 30px;
}
.el-descriptions .el-descriptions__body {
    padding: 5px 10px;
}