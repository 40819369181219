* {
	margin: 0;
	padding: 0;
}
html,
body,
#app,
.wrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 8px;
	/*高宽分别对应横竖滚动条的尺寸*/
	height: 8px;
}
::-webkit-scrollbar-thumb {
	/*滚动条里面小方块*/
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #999;
}
::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #EDEDED;
}
.content-box {
	position: absolute;
	left: 200px;
	right: 0;
	top: 0;
	bottom: 0;
	padding-bottom: 20px;
	transition: left .3s ease-in-out;
	background: #f0f0f0;
	display: flex;
	flex-direction: column;
	min-width: 500px;
}
.content {
	flex: 1;
	box-sizing: border-box;
	margin: 0 20px;
	position: relative;
}
.content-collapse {
	left: 65px;
}
.container {
	background: #FFFFFF;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.page-container {
	position: absolute;
	overflow: auto;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 15px 10px 10px 10px;
	top: 0
}
.content .el-dialog {
	min-height: 100%;
	height: auto !important;
	overflow: initial !important;
}
.content .el-dialog__wrapper {
	position: relative !important;
	height: 100%;
}
.upload-img .el-dialog__wrapper {
	position: fixed !important;
}
.content .el-dialog__header {
	width: 100%;
	box-sizing: border-box;
	position: sticky !important;
	top: 0;
	z-index: 165;
	background-color: #FFFFFF;
	border-bottom: 1px solid #D9D9D9;
	padding: 18px;
	padding-bottom: 18px;
}
.content .el-dialog__header .el-dialog__title {
	font-size: 16px;
}
.content .el-dialog__body {
	height: 100%;
	padding: 30px 20px 30px 20px !important;
	box-sizing: border-box;
}
.content .el-dialog__body .container {
	min-height: calc(100vh - 250px);
}
.content .el-dialog__body .container .page-container {
	padding: 76px 25px 0 20px;
}

/* .el-form-item__content .el-button {
	margin-left: 0 !important;
} */
.el-pagination {
	padding: 10px 5px !important;
	text-align: right;
}


/* 侧边栏 */
.el-menu {
	border-right: none !important;
}
.el-menu--collapse {
	width: 65px !important;
}
.el-submenu.is-active .el-submenu__title {
	color: #FFFFFF !important;
}
.el-submenu.is-active .el-submenu__title i {
	color: #FFFFFF !important;
}
.el-submenu .el-submenu__title {
	height: 50px !important;
	line-height: 50px !important;
	color: #A2AAB0 !important;
}
.el-submenu .el-submenu__title:hover {
	background-color: transparent !important;
	color: #FFFFFF !important;
}
.el-submenu .el-submenu__title:hover i,
.el-submenu .el-submenu__title:focus i {
	color: #FFFFFF !important;
}
.el-submenu .el-submenu__title i {
	color: #A2AAB0 !important;
}
.el-submenu .el-menu-item {
	padding: 0px 45px !important;
}
.el-submenu .el-menu-item:not(:last-child) {
	border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.el-submenu .el-menu-item:hover {
	background-color: transparent !important;
	color: #FFFFFF !important;
}
.el-submenu .el-menu-item:focus {
	background-color: #0960BD !important;
}
.el-submenu .el-menu-item-group__title {
	padding: 0px !important;
}
.el-submenu .el-menu-item.is-active {
	color: #FFFFFF !important;
	background-color: #0960BD !important;
}
.el-submenu [class^=el-icon-] {
	width: 16px !important;
	font-size: 16px !important;
	margin-right: 0px !important;
}
.el-submenu .el-submenu__title .menu-text{
	margin-left: 10px;
}


/* 取消el-link下划线 */
.el-link.el-link--primary.is-underline:hover:after {
	border-bottom: none;
}


/* 对号，叉号 */
.el-table__row .el-icon-check {
	color: #67C23A;
	font-size: 18px;
	font-weight: bold;
	line-height: 23px;
}
.el-table__row .el-icon-close {
	color: #F56C6C;
	font-size: 18px;
	font-weight: bold;
	line-height: 23px;
}


/* 重置密码 */
.forgetpwd-wrap .code {
	font-size: 14px;
	width: 54%;
	margin-right: 2%;
}
.forgetpwd-wrap .code-btn {
	padding: 10px 12px;
}


/* 登录 */
.login-bg .el-form-item--small.el-form-item {
	margin-bottom: 24px;
}
.login-bg .el-input--medium .el-input__inner {
	height: 40px;
	line-height: 40px;
}
.login-bg .el-button--medium {
	padding: 12px 20px;
}
.login-bg .el-dialog__wrapper {
	position: fixed !important;
	height: auto !important;
}
.login-bg .el-dialog {
	min-height: auto !important;
	height: auto !important;
}


/* 弹出框底部按钮 */
.dialog-footer {
	width: 100%;
}
.dialog-footer button {
	padding: 9px 26px;
	margin-top: 12px;
}


/* 更改form的label位置 */
.form-wrap {
	padding-left: 80px;
}
.form-position-top .el-form-item__label {
	float: none;
}
.form-position-top .el-form-item__content {
	margin-left: 0 !important;
}
.choose-city .el-select {
	margin-right: 10px;
}


/* tab表格图片样式 */
.cell .el-image img {
	width: 40px;
	height: 40px;
	-o-object-fit: contain;
	   object-fit: contain;
}


/* 弹窗多层使用 */
.content .el-dialog__wrapper.absolute {
	position: absolute !important;
}


/* 编辑器层级 */
.w-e-full-screen-container {
	z-index: 99999999;
}


/* 富文本编辑器 */
.w-e-text-container [data-slate-editor] p {
	margin: 0px !important;
}
.w-e-text-container [data-slate-editor] ul li {
	list-style-type: disc !important;
}
.w-e-text-container [data-slate-editor] ol li {
	list-style-type: decimal !important;
}


/* 文本链接样式 */
.el-table__body .el-table__row .el-link {
	font-size: 12px;
}

/* form表单 */
.form-wrap .el-form-item--small.el-form-item {
	margin-bottom: 28px;
}
.form-wrap .el-form-item__content .el-form-item--small.el-form-item {
	margin-bottom: 0px;
}
.file-item .el-input__inner {
	border: 0;
	padding: 0 12px;
}


/* 页面筛选form的small大小样式 */
.page-search .el-form-item--small.el-form-item{
    margin-bottom: 15px;
}


/* table表头背景色 */
.el-table .el-table__header th.el-table__cell{
    background: #fafafa;
    color: #000000d9;
    font-weight: 500;
    /* background: rgb(236, 241, 254) !important; */
}
.el-table .el-table__body .el-table__row td{
    color: #000000d9;
}
.el-table .el-table__body .el-table__row td:hover{
	background-color: #fafafa;
}
.el-table .el-table__body .el-table__row.hover-row td{
    background-color: #fafafa;
}
.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #fafafa !important;
}




/* 详情遮罩弹窗 */
/* .content .dialog-detail-wrap.el-dialog__wrapper{
	max-height: 100% !important;
	display: flex;
	justify-content:center;
	flex-direction: column;
} */
.content .dialog-detail-wrap.el-dialog__wrapper .el-dialog{
	width: 100%;
	/* min-height: 0% !important; */
	max-height: 100% !important;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}
.content .dialog-detail-wrap.el-dialog__wrapper .el-dialog .el-dialog__body{
	width: 100%;
	max-height: 100%;
	overflow: auto;
	padding: 15px 10px !important;
    box-sizing: border-box;
}




/* 公用---状态字体颜色 */
.status-not{
	color: #409EFF;
}
.status-conduct{
	color: #E6A23C;
}
.status-fail {
	color: red;
}
.status-success {
	color: #00bd93;
}
.el-tree-node__content {
	min-height: 40px;
}
.el-rate__icon {
    font-size: 25px !important;
}