
* { margin-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 0px;
}
html,body, h1, h2, h3, h4, h5, h6, dl, dt, dd, ul, ol, li, th, td, p, blockquote, pre, form, fieldset, legend, input, button, textarea, hr, select {margin:0;padding:0;}
html,body{font-family:"微软雅黑",Arial,Helvetica,sans-serif;-webkit-text-size-adjust:100%;color:#333;margin:0;padding:0;}
table {border-collapse:collapse; border-spacing:0;}
ol, ul, li {list-style:none;}
fieldset, img {border:0;}
q:before, q:after {content:'';}
a:focus, input, textarea {outline-style:none;}/*对ie7+有效*/
input:focus{
	outline: 1px solid #0E7AF4;
}
input[type="text"], input[type="password"], textarea {outline-style:none;-webkit-appearance:none;}
textarea {resize:none}
address, caption, cite, code, dfn, em, i, th, var, b {font-style:normal;font-weight:normal;}
abbr, acronym {border:0;font-variant:normal;}
a {text-decoration:none; color: #333;}
a:hover {text-decoration:none;}
img{border:0;vertical-align:middle;}
select {
	outline: none;
	/*Chrome和Firefox里面的边框是不一样的，所以复写了一下*/
	border: 1px solid #d8d8d8;
	/*很关键：将默认的select选择框样式清除*/
	appearance:none;
	-moz-appearance:none;
	-webkit-appearance:none;
	/*在选择框的最右侧中间显示小箭头图片*/
	/* background: url("../img/down.png") no-repeat scroll right center transparent; */
	/*为下拉小箭头留出一点位置，避免被文字覆盖*/
	padding-right: 14px;
}
/*清除ie的默认选择框样式清除，隐藏下拉箭头*/
select::-ms-expand { display: none;
}
.fl{float: left;}
.fr{float: right;}
.clearfix::after {display:block;clear:both;content:"";visibility:hidden;height:0}
.clearfix {zoom:1}
.ellipsis{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}



/* 定义滚动条样式 */
.el-dialog__body .page-container::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
/*定义滚动条轨道 内阴影+圆角*/
.el-dialog__body .page-container::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #EDEDED;
	margin-top: 61px;
}
/*定义滑块 内阴影+圆角*/
.el-dialog__body .page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #999;
}
.el-input__inner{
	color: #323232;
}
.el-textarea__inner{
	color: #323232;
}
.el-textarea__inner::-webkit-input-placeholder{
    color: #909090;
}/*webkit 内核浏览器*/
.el-textarea__inner::-moz-placeholder{
    color: #909090;
}/*Mozilla Firefox 19+*/
.el-textarea__inner:-moz-placeholder{
    color: #909090;
}/*Mozilla Firefox 4 to 18*/
.el-textarea__inner:-ms-input-placeholder{
    color: #909090;
}/*Internet Explorer 10+*/

/* 定义滚动条样式 */
.el-textarea__inner::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}
/*定义滚动条轨道 内阴影+圆角*/
.el-textarea__inner::-webkit-scrollbar-track {
	/* box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.6); */
	border-radius: 10px;
	background-color: transparent;
}
/*定义滑块 内阴影+圆角*/
.el-textarea__inner::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 0px rgba(153, 153, 153, 1);
	background-color: rgba(153, 153, 153, 1);
}